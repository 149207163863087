import { Link, useNavigate } from 'react-router-dom';
import { ASSETS } from '../constants';
import { useState, useRef, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Session } from '@supabase/supabase-js';

interface NavbarProps {
  onScrollToSection?: (sectionId: string) => void;
  showNavigation?: boolean;
  session: Session | null;  
}

const navItems = [
  { id: 'hero', label: 'Home' },
  { id: 'scenarios', label: 'Why Flippy?' },
  { id: 'features', label: 'Features' },
  { id: 'pricing', label: 'Pricing' },
];

export const Navbar = ({ onScrollToSection, showNavigation = true, session }: NavbarProps) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear any local storage or state
      localStorage.clear();
      
      // Force a page reload to clear all state
      window.location.href = '/';
    } catch (error: any) {
      console.error('Error during sign out:', error.message);
    }
  };

  const handleNavigation = (sectionId: string) => {
    if (onScrollToSection) {
      onScrollToSection(sectionId);
    } else {
      navigate('/#' + sectionId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="flex justify-between items-center bg-white px-6 py-4 shadow-sm fixed top-0 left-0 w-full z-50 border-b border-gray-100">
      {/* Left Section: Logo and Text */}
      <Link to="/" className="flex items-center space-x-4 group">
        <div className="relative w-10 h-10 transform transition-transform duration-200 group-hover:scale-105">
          <img 
            src={ASSETS.LOGO} 
            alt="Flippy Logo" 
            className="w-full h-full object-contain"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
            Flippy
          </span>
          <span className="text-sm text-gray-500">
            Enjoy your favorite content!
          </span>
        </div>
      </Link>

      {/* Center: Navigation */}
      {showNavigation && (
        <div className="hidden md:flex items-center space-x-1">
          {navItems.map((item) => (
            <button
              key={item.id}
              onClick={() => handleNavigation(item.id)}
              className="px-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-900 rounded-md hover:bg-gray-50 transition-all duration-200"
            >
              {item.label}
            </button>
          ))}
        </div>
      )}

      {/* Right Section: Auth Buttons or User Icon */}
      <div className="flex items-center space-x-4">
        {session && (
          <div className="relative" ref={dropdownRef}>
            <button 
              className="flex items-center justify-center w-10 h-10 bg-gray-50 rounded-full hover:bg-blue-50 hover:text-blue-600 transition-all duration-200 transform hover:scale-105 border border-gray-200"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <svg 
                className="w-5 h-5 text-gray-600"
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1">
                <Link
                  to="/home"
                  className="block px-4 py-2 text-sm text-gray-700 hover:text-blue-600 hover:bg-blue-50 transition-colors flex items-center space-x-2"
                  onClick={() => setIsDropdownOpen(false)}
                >
                  <svg 
                    className="w-4 h-4" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2"
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  >
                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                    <line x1="3" y1="9" x2="21" y2="9" />
                    <path d="M9 21V9" />
                  </svg>
                  <span>My Flippy</span>
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:text-blue-600 hover:bg-blue-50 transition-colors flex items-center space-x-2"
                >
                  <svg 
                    className="w-4 h-4" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2"
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1="21" y1="12" x2="9" y2="12" />
                  </svg>
                  <span>Logout</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
