import React from 'react';
import { HiArrowRight } from "react-icons/hi2";

/**
 * A component that promotes the Flippy Library feature
 */
export const LibraryPromotion = () => {
  return (
    <div className="bg-gradient-to-r from-amber-100 to-teal-100 rounded-2xl p-6 sm:p-8 shadow-lg border border-amber-200 relative overflow-hidden transform transition-all duration-300 hover:scale-[1.01] hover:shadow-xl">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-40 h-40 bg-amber-200 rounded-full -translate-y-1/2 translate-x-1/2 opacity-60"></div>
      <div className="absolute bottom-0 left-0 w-32 h-32 bg-teal-200 rounded-full translate-y-1/2 -translate-x-1/2 opacity-60"></div>
      <div className="absolute -bottom-4 right-1/4 w-24 h-24 bg-amber-100 rounded-full opacity-40"></div>
      
      {/* Content */}
      <div className="relative z-10">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <div className="flex-1 text-center md:text-left">
            <div className="flex items-center justify-center md:justify-start mb-2">
              <span className="inline-block px-3 py-1 text-xs font-bold bg-red-500 text-white rounded-full animate-pulse mr-2">NEW</span>
              <span className="text-sm font-medium text-gray-600">Just Launched</span>
            </div>
            <h2 className="text-2xl md:text-3xl font-bold mb-3">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-amber-500 to-teal-500">
                Discover Flippy Library
              </span>
            </h2>
            <p className="text-base md:text-lg text-gray-700 mb-6 max-w-2xl">
              Discover our new hub for blogs, articles, bookmarks, and best content in the web — all in one place.
            </p>
          </div>
          <div className="flex-shrink-0">
            <a
              href="https://app.hiflippy.xyz?utm_source=flippy-web&utm_medium=promotion&utm_campaign=library_launch&utm_content=hero_section"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg shadow-md text-white bg-gradient-to-r from-amber-500 to-amber-600 hover:from-amber-600 hover:to-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition-all duration-200 hover:shadow-lg hover:-translate-y-0.5"
            >
              Explore Library
              <HiArrowRight className="ml-2 -mr-1 h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
